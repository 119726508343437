import React, { useEffect, useState } from "react";
import { Amplify } from "aws-amplify";
import { Loader, ThemeProvider } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { FaceLivenessDetector } from "@aws-amplify/ui-react-liveness";
import { View, Flex, Image } from "@aws-amplify/ui-react";
import AWS from "aws-sdk";
import awsexport from "../../aws-exports";
Amplify.configure(awsexport);

AWS.config.update({
  accessKeyId: "AKIAZL2GKOAQBBBXWD6H",
  secretAccessKey: "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",
  region: "ap-south-1",
});

export default function FaceLiveness({ handleImage }) {
  const [loading, setLoading] = useState(true);
  const [sessionId, setSessionId] = useState(null);
  const [faceLivenessAnalysis, setFaceLivenessAnalysis] = useState(null);
  const [recognizedPerson, setRecognizedPerson] = useState(null);
  const rekognition = new AWS.Rekognition();
  const dynamodb = new AWS.DynamoDB();

  useEffect(() => {
    const fetchCreateLiveness = async () => {
      const response = await fetch(
        "https://3oelohkbbf.execute-api.ap-south-1.amazonaws.com/dev/get/session"
      );
      const data = await response.json();
      setSessionId(data.sessionId);
      setLoading(false);
    };
    fetchCreateLiveness();
  }, [loading]);

  const handleAnalysisComplete = async () => {
    const response = await fetch(
      "https://3oelohkbbf.execute-api.ap-south-1.amazonaws.com/dev/get/session/results",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ sessionId: sessionId }),
      }
    );
    const data = await response.json();
    if (data.Confidence < 70) {
      throw new Error()
    }
    setFaceLivenessAnalysis(data);
    const byteArray = new Uint8Array(data.ReferenceImage.Bytes.data);
    const imageByt = data.ReferenceImage.Bytes;
    const binaryString = imageByt.data.reduce(
      (data, byte) => data + String.fromCharCode(byte),
      ""
    );
    const base64Image = btoa(binaryString);
    const blob = new Blob([byteArray], { type: "image/jpeg" });
    // const imageUrl = URL.createObjectURL(blob);
    handleImage(base64Image);
  };


  return (
    <ThemeProvider>
      <Flex
        direction="row"
        justifyContent="center"
        alignItems="center"
        alignContent="flex-start"
        wrap="nowrap"
        gap="1rem"
      >
        <View
          as="div"
          maxHeight="600px"
          height="600px"
          width="740px"
          maxWidth="740px"
        >
          {loading ? (
            <Loader />
          ) : (
            !(faceLivenessAnalysis && recognizedPerson) && (
              <>
              <FaceLivenessDetector
                sessionId={sessionId}
                region="ap-south-1"
                onAnalysisComplete={handleAnalysisComplete}
                onUserCancel={() => setLoading(true)}
              />
              </>
            )
          )}
        </View>
      </Flex>
    </ThemeProvider>
  );
}
