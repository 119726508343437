import React, { useRef, useState } from "react";
import FaceLiveness from "./FaceLiveness";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import axios from "axios";
import { toast } from "react-toastify";
import { clientSecret, token } from "./Layout";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Loader from "../components/Loader";
import { Spinner } from "react-bootstrap";

const schema = yup
  .object({
    aadharNumber: yup.string().length(14).required(),
    consent: yup.boolean().oneOf([true]).required(),
    otpNumber: yup.string().when("$isOtpRequired", {
      is: (isOtpRequired) => isOtpRequired === true,
      then: (schema) => yup.string().length(6).required(),
      otherwise: (schema) => schema,
    }),
  })
  .required();

const url = process.env.API_URL;
export default function AAdharVerification() {
  const [image, setImage] = useState(null);
  const [otpRes, setOtpRes] = useState(null);
  const [loading, setLoading] = useState(false);
  const [verificationCompleted, setVerificationCompleted] = useState(false);
  const [timer, setTimer] = useState("00:00");
  const timerRef = useRef();
  const dataRef = useRef();
  // handler for images
  const handleImage = async (b64Image) => {
    setImage(b64Image);
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    context: { isOtpRequired: otpRes && otpRes?.status },
    defaultValues: {
      aadharNumber: "",
      consent: false,
      otpNumber: "",
    },
  });

  // form submit

  const aadharSubmit = (data) => {
    setLoading(true);
    if (otpRes && otpRes?.status) {
      axios
        .post(`/api/v1/external/gateway/verify/otp/`, {
          doc_number: otpRes?.data?.doc_number,
          client_secret: clientSecret,
          token: token,
          ref_id: otpRes?.data?.ref_id,
          otp: Number(data?.otpNumber),
          image: image,
        })
        .then(function (response) {
          toast.success(response?.data?.message);
          setVerificationCompleted(true);
        })
        .catch(function (error) {
          toast.error(error?.response?.data?.message);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      axios
        .post(`/api/v1/external/gateway/send/otp/`, {
          doc_number: Number(data?.aadharNumber.replace(/\s+/g, "")),
          client_secret: clientSecret,
          token: token,
        })
        .then(function (response) {
          dataRef.current = response?.data?.data;
          toast.success(response?.data?.message);
          setOtpRes(response?.data);
        })
        .catch(function (error) {
          toast.error(error?.response?.data?.message);
        })
        .finally(() => {
          setLoading(false);
          clearTimer(getDeadTime());
        });
    }
  };

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    let { total, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      // update the timer
      // check if less than 10 then we need to
      // add '0' at the beginning of the variable
      setTimer(
        (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    }
  };

  const clearTimer = (e) => {
    // If you adjust it you should also need to
    // adjust the Endtime formula we are about
    // to code next
    setTimer("00:59");

    // If you try to remove this line the
    // updating of timer Variable will be
    // after 1000ms or 1sec
    if (timerRef.current) clearInterval(timerRef.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    timerRef.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();

    // This is where you need to adjust if
    // you entend to add more time
    deadline.setSeconds(deadline.getSeconds() + 59);
    return deadline;
  };

  const resendOtp = () => {
    setLoading(true);
    axios
      .post(`/api/v1/external/gateway/send/otp/`, {
        doc_number: dataRef.current.doc_number,
        client_secret: clientSecret,
        token: token,
      })
      .then(function (response) {
        toast.success(response?.data?.message);
        setOtpRes(response?.data);
      })
      .catch(function (error) {
        toast.error(error?.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
        clearTimer(getDeadTime());
      });
  };
  return (
    <>
      {loading && (
        <div className="loader">
          <Spinner animation="border" variant="light" />
        </div>
      )}
      {verificationCompleted ? (
        <div className="no-match">
          <div className="text-center">
            <h1
              className="text-error"
              style={{
                textShadow: "none",
                lineHeight: "normal",
                fontSize: "4rem",
              }}
            >
              Your Verification has been completed...
            </h1>
          </div>
        </div>
      ) : !image ? (
        <FaceLiveness handleImage={handleImage} />
      ) : (
        <div className="wrapper">
          <div className="wrapper-sec">
            <div className="wrapper-title">Enter your Aadhar details!</div>
            <div className="wrapper=body">
              <Form onSubmit={handleSubmit(aadharSubmit)} noValidate>
                <Form.Group
                  className="mb-4"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Aadhar number</Form.Label>
                  <Controller
                    name="aadharNumber"
                    control={control}
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        maxLength={14}
                        placeholder="xxxx xxxx xxxx"
                        type="password"
                        autoComplete="new-password"
                        onChange={(e) => {
                          let value = e.target.value
                            .replace(/[^\d]/g, "")
                            .replace(/\s+/g, "")
                            .replace(/(\d{4})(?=\d)/g, "$1 ");
                          field.onChange(value);
                        }}
                        required
                      />
                    )}
                  />
                  <p style={{ color: "red", marginTop: "2px" }}>
                    {errors?.aadharNumber?.message.length > 0 &&
                      "Please provide valid Aadhar number"}
                  </p>
                </Form.Group>
                <Form.Group className="mb-4" controlId="aadhar-chek">
                  <Controller
                    name="consent"
                    control={control}
                    render={({ field }) => (
                      <Form.Check
                        {...field}
                        checked={field.value}
                        type="checkbox"
                        label="I hereby consent to the use of Aadhaar Number provided in the
          application for Identity Validation."
                        disabled={otpRes?.status}
                        feedbackType="invalid"
                        isInvalid={!!errors.consent}
                      />
                    )}
                  />
                </Form.Group>
                {otpRes && otpRes?.status && (
                  <Form.Group
                    className="mb-4"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>OTP</Form.Label>
                    <Controller
                      name="otpNumber"
                      control={control}
                      render={({ field }) => (
                        <Form.Control
                          {...field}
                          placeholder="xxxxxx"
                          maxLength={6}
                          onChange={(e) => {
                            let value = e.target.value.replace(/\D/g, "");
                            if (value.length > e.target.maxLength)
                              value = value.slice(0, e.target.maxLength);
                            field.onChange(value);
                          }}
                        />
                      )}
                    />
                    <p style={{ color: "red", marginTop: "2px" }}>
                      {errors?.otpNumber?.message.length > 0 &&
                        "Please provide valid OTP number"}
                    </p>
                  </Form.Group>
                )}
                <div className="d-flex justify-content-between">
                  {otpRes && otpRes?.status && (
                    <Button
                      type="button"
                      onClick={resendOtp}
                      variant="outline-secondary"
                      style={{ flex: 1, marginRight: "15px" }}
                      disabled={timer > "00:00"}
                    >
                      {timer > "00:00"
                        ? `Resend OTP in : ${timer}`
                        : "Resend OTP"}
                    </Button>
                  )}

                  <Button type="submit" variant="primary" style={{ flex: 1 }}>
                    Submit
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
